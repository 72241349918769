import { Profile } from "@/interfaces/profile";
import { AppStoreType } from "@/store";
import { ActionContext, ActionTree, GetterTree } from "vuex";

/************************
 * Profile-Store interfaces
 ************************/
/**
 * Interface for the profile state.
 */
export interface ProfileState {
  currentUserProfile: Profile | null;
}

/**
 * Enumeration for the mutations of the profile state.
 */
export enum Mutations {
  SET_PROFILE = "SET_PROFILE",
  GET_PROFILE = "GET_PROFILE",
  EDIT_PROFILE = "EDIT_PROFILE",
}

/**
 * Enumeration for the actions of the profile state.
 */
export enum Getters {
  CURRENT_USER_PROFILE = "CURRENT_USER_PROFILE",
}

/**
 * An Interface describes the shape of profile getters
 */
export interface ProfileGetters extends GetterTree<ProfileState, AppStoreType> {
  [Getters.CURRENT_USER_PROFILE]: (state: ProfileState) => Profile | null;
}

/**
 * Enumeration for the actions of the profile state.
 */
export enum Actions {
  GET_PROFILE = "GET_PROFILE",
}
/**
 * An Interface describes the shape of profile actions
 */
export interface ProfileActions extends ActionTree<ProfileState, AppStoreType> {
  [Actions.GET_PROFILE]: (
    { commit }: ActionContext<ProfileState, AppStoreType>,
    payload?: { id: string }
  ) => Promise<void>;
}
