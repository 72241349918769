import { concat } from "ramda";

const toCamel = (str) =>
  str.toLowerCase().replace(/[-_]([a-z])/g, (m) => m[1].toUpperCase());

const snakeCase = (str) =>
  str.replace(/([A-Z])/g, (x) => concat("_", x.toLowerCase()));

// const toCamel = (str) => {
//   return str.replace(/([-_][a-z])/gi, ($1) => {
//     return $1.toUpperCase().replace("-", "").replace("_", "");
//   });
// };

const isObject = function (obj) {
  return (
    obj === Object(obj) && !Array.isArray(obj) && typeof obj !== "function"
  );
};

export const toCamelCase = function (obj) {
  if (isObject(obj)) {
    const n = {};

    Object.keys(obj).forEach((k) => {
      n[toCamel(k)] = toCamelCase(obj[k]);
    });

    return n;
  } else if (Array.isArray(obj)) {
    return obj.map((i) => {
      return toCamelCase(i);
    });
  }

  return obj;
};

export const toSnakeCase = function (obj) {
  if (isObject(obj)) {
    const n = {};

    Object.keys(obj).forEach((k) => {
      n[snakeCase(k)] = toSnakeCase(obj[k]);
    });

    return n;
  } else if (Array.isArray(obj)) {
    return obj.map((i) => {
      return toSnakeCase(i);
    });
  }

  return obj;
};

export const capitalize = (text) => {
  const [firstChar, ...rest] = text;
  return `${firstChar.toUpperCase()}${rest.join("")}`;
};
