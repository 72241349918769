import { getCurrentInstance } from "vue";
import { useSettingsGetters } from "@/store/app-settings";
import { Getters as SettingsGetters } from "@/store/app-settings/settings.types";

export const useIsRtl = (): boolean => {
  const vm = getCurrentInstance();

  if (!vm) {
    throw new Error("useHttp was called outside of the setup method.");
  }

  const { [SettingsGetters.IS_RTL]: isRtl } = useSettingsGetters([
    SettingsGetters.IS_RTL,
  ]);
  return isRtl.value;
};
