import { VueI18n } from "vue-i18n";
import { getCurrentInstance, inject } from "vue";
import { i18Symbol } from "../main";
export const useI18n = (): VueI18n => {
  const vm = getCurrentInstance();

  if (!vm) {
    throw new Error("useI18n was called outside of the setup method.");
  }

  return inject(i18Symbol) as VueI18n;
};
