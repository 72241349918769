import { AppStoreType } from "@/store";
import { Mood } from "@/interfaces/mood";
import { ActionContext, ActionTree, GetterTree, MutationTree } from "vuex";

export interface MoodState {
  userMood: Mood | null;
}

export enum Mutations {
  SET_MOOD = "SET_MOOD",
  GET_MOOD = "GET_MOOD",
  EDIT_MOOD = "EDIT_MOOD",
  REMOVE_MOOD = "REMOVE_MOOD",
}

export enum Getters {
  USER_MOOD = "USER_MOOD",
}

export enum Actions {
  GET_MOOD = "GET_MOOD",
  ADD_MOOD = "ADD_MOOD",
}

export interface MoodGetters extends GetterTree<MoodState, AppStoreType> {
  [Getters.USER_MOOD]: (state: MoodState) => Mood | null;
}

export interface MoodMutation extends MutationTree<MoodState> {
  [Mutations.REMOVE_MOOD]: (state: MoodState) => void;
}
export interface MoodActions extends ActionTree<MoodState, AppStoreType> {
  [Actions.GET_MOOD]: ({
    commit,
  }: ActionContext<MoodState, AppStoreType>) => Promise<void>;

  [Actions.ADD_MOOD]: (
    { commit }: ActionContext<MoodState, AppStoreType>,
    modeId: number
  ) => Promise<void>;
}
