import { Actions, ProfileActions, Mutations } from "./profile.types";
import { toCamelCase } from "@/helper/utils";
import userService from "@/services/user.service";

const actions: ProfileActions = {
  /**
   * Gets the profile  of the current user.
   * @param commit callback for committing a mutation
   * @param payload contains the userId (for other users)
   */
  [Actions.GET_PROFILE]: async (
    { commit },
    payload?: { id: string }
  ): Promise<void> => {
    const profile = await userService.getProfile(payload ? payload.id : "");
    commit(Mutations.SET_PROFILE, toCamelCase(profile));
  },
};

export default actions;
