import { onMounted, onUnmounted, reactive } from "vue";

/**
 * Enumeration of all Screen-sizes from small to extra large.
 *
 * IMPORTANT: The order of all screenSizes have to be from the smallest to the largest one!
 */
export enum ScreenSize {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  xxl = "xxl",
}

/**
 * Class representing information about the current client window dimensions.
 */
export class ClientDimensions {
  constructor(
    private _innerWidth: number,
    private _innerHeight: number,
    private _screenSize: ScreenSize
  ) {}

  public get innerWidth(): number {
    return this._innerWidth;
  }

  public set innerWidth(newInnerWidth: number) {
    this._innerWidth = newInnerWidth;
  }

  public get innerHeight(): number {
    return this._innerHeight;
  }

  public set innerHeight(newInnerHeight: number) {
    this._innerHeight = newInnerHeight;
  }

  public get screenSize(): ScreenSize {
    return this._screenSize;
  }

  public set screenSize(newScreenSize: ScreenSize) {
    this._screenSize = newScreenSize;
  }

  /**
   * Method return if the given screenSize is greater or equal the current screenSize
   *
   * @param screenSize - screenSize to check
   */
  public isLargerOrEqual(screenSize: ScreenSize): boolean {
    return (
      Object.keys(ScreenSize).indexOf(this._screenSize) >=
      Object.keys(ScreenSize).indexOf(screenSize)
    );
  }
}

/**
 * Composition function providing active breakpoint and screen dimensions.
 *
 * @returns a ClientDimensions instance
 */
export const useClientDimensions = () => {
  /**
   * Client dimensions are initially 0 - 0 and have a ScreenSize 'xs' - because the idea of Bootstrap is
   * "small/mobile first". So the server rendered content is for mobile screens.
   */
  const clientDimensions = reactive(new ClientDimensions(0, 0, ScreenSize.xs));

  /**
   * Calculates the screen size depending on the innerWidth of the client.
   */
  const getScreenSize = (): ScreenSize => {
    switch (true) {
      case clientDimensions.innerWidth >= 1400:
        return ScreenSize.xxl;

      case clientDimensions.innerWidth >= 1200:
        return ScreenSize.xl;

      case clientDimensions.innerWidth >= 992:
        return ScreenSize.lg;

      case clientDimensions.innerWidth >= 768:
        return ScreenSize.md;

      case clientDimensions.innerWidth >= 576:
        return ScreenSize.sm;

      default:
        return ScreenSize.xs;
    }
  };

  /** Set dimensions and active breakpoint. */
  const handleResize = () => {
    clientDimensions.innerWidth = window.innerWidth;
    clientDimensions.innerHeight = window.innerHeight;
    clientDimensions.screenSize = getScreenSize();
  };

  // Add resize listener on mounted to be SSR conform.
  onMounted(() => {
    // Set initial values.
    handleResize();

    window.addEventListener("resize", handleResize);
  });

  // Remove resize listener on unmounted.
  onUnmounted(() => {
    window.removeEventListener("resize", handleResize);
  });

  return { clientDimensions };
};
