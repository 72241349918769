import axios, { AxiosError } from "axios";
import store from "@/store";
import { AUTH_MODULE_NAME } from "@/store/Auth";
import { Getters as AuthGetters, Mutations } from "@/store/Auth/auth.types";
import router from "@/router";
//@ts-ignore
import { $vfm } from "vue-final-modal";

export const initializeAxios = () => {
  // set the backend url according to the environment
  axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

  // attach the auth token to every request if it exists
  axios.interceptors.request.use(
    (config) => {
      /** show loading spinner */
      // store.commit(
      //   `${SETTINGS_MODULE_NAME}/${SettingsMutations.SET_LOADING}`,
      //   true
      // );

      const token =
        store.getters[`${AUTH_MODULE_NAME}/${AuthGetters.CURRENT_USER_TOKEN}`];

      if (token && config && config.headers) {
        config.headers["Authorization"] = token;
      }

      return config;
    },

    (error) => {
      /** stop loading spinner */
      // store.commit(
      //   `${SETTINGS_MODULE_NAME}/${SettingsMutations.SET_LOADING}`,
      //   false
      // );
      return Promise.reject(error);
    }
  );

  // attach the auth token to every request if it exists
  axios.interceptors.response.use(
    (config) => {
      /** stop loading spinner */
      // store.commit(
      //   `${SETTINGS_MODULE_NAME}/${SettingsMutations.SET_LOADING}`,
      //   false
      // );
      return config;
    },

    (error: AxiosError) => {
      /** stop loading spinner */
      // store.commit(
      //   `${SETTINGS_MODULE_NAME}/${SettingsMutations.SET_LOADING}`,
      //   false
      // );
      switch (error.response?.status) {
        case 401:
          store.commit(`${AUTH_MODULE_NAME}/${Mutations.LOGOUT}`);
          $vfm.hideAll();
          router.push({
            name: "auth1.sign-in1",
          });
      }
      return Promise.reject(error);
    }
  );
};
