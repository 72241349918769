import { UserSettings } from "./../../interfaces/user";
import { Getters, SettingsGetters, SettingsState } from "./settings.types";

const getters: SettingsGetters = {
  /**
   * The current loading state.
   *
   * @param state the settings state
   * @returns if the app is loading
   */
  [Getters.LOADING]: (state: SettingsState): boolean => state.loading,

  [Getters.USER_SETTINGS]: (state: SettingsState): UserSettings =>
    state.settings,

  [Getters.IS_RTL]: (state: SettingsState): boolean => state.rtl,
};

export default getters;
