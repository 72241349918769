import { stringBooleanMapper } from "./../helper/string-boolean-mapper";
import { AUTH_MODULE_NAME } from "./../store/Auth/index";
import {
  createRouter,
  createWebHashHistory,
  NavigationGuard,
} from "vue-router";
import store from "@/store";
import { Getters } from "@/store/Auth/auth.types";
import userService from "@/services/user.service";

const childRoutes = (prop: string) => [
  {
    path: "",
    name: prop + ".list",
    meta: { auth: true, name: "Social App" },
    component: () => import("@/views/Apps/Social/SocialApp.vue"),
  },
  {
    path: "accountsettings",
    name: prop + ".account-settings",
    meta: { auth: true, name: "Privacy Setting" },
    component: () => import("@/views/AccountSettings/AccountSetting.vue"),
  },
  {
    path: "profile",
    name: prop + ".profilemain",
    meta: { auth: true, name: "Profile" },
    component: () => import("@/views/Profile/Profile.vue"),
  },
  {
    path: "profile/:id",
    name: prop + ".profileDetails",
    meta: { auth: true, name: "Profile" },
    component: () => import("@/views/Profile/Profile.vue"),
    props: true,
  },
  {
    path: "search",
    name: prop + ".search",
    meta: { auth: true, name: "Search" },
    component: () => import("@/views/search/Search.vue"),
  },
];
const blankchildRoutes = (prop: string) => [
  {
    path: "signin",
    name: prop + ".sign-in1",
    component: () => import("@/views/AuthPages/Default/SignIn1.vue"),
    meta: {
      isPublic: true,
    },
  },
  {
    path: "recover-password",
    name: prop + ".recover-password",
    component: () => import("@/views/AuthPages/Default/RecoverPassword1.vue"),
    meta: {
      isPublic: true,
    },
  },
  {
    path: "forgot-reset-password",
    name: prop + ".forgot-reset-password",
    component: () =>
      import("@/views/AuthPages/Default/ForgotResetPassword.vue"),
    meta: {
      isPublic: true,
    },
  },
  {
    path: "change-password",
    name: prop + ".change-password",
    component: () => import("@/views/AuthPages/Default/ChangePassword.vue"),
    meta: {
      auth: true,
    },
  },
];
const pagesChildRoutes = (prop: string) => [
  {
    path: "error/:code",
    name: prop + ".error",
    component: () => import("@/views/Pages/ErrorPage.vue"),
  },
  {
    path: "error404",
    name: prop + ".error404",
    component: () => import("@/views/Pages/Error404.vue"),
  },
  {
    path: "error500",
    name: prop + ".error500",
    component: () => import("@/views/Pages/Error500.vue"),
  },
];
const storechildRoutes = (prop: string) => [
  {
    path: "charities",
    name: prop + ".charities",
    meta: { auth: true, name: "charities" },
    component: () => import("@/views/Charities/CharitiesList.vue"),
  },
  {
    path: "gifts",
    name: prop + ".gifts",
    meta: { auth: true, name: "gifts" },
    component: () => import("@/views/Gifts/GiftList.vue"),
  },
  {
    path: "orders",
    name: prop + ".orders",
    meta: { auth: true, name: "orders" },
    component: () => import("@/views/orders/Orders.vue"),
  },
];

const routes = [
  {
    path: "/",
    name: "social",
    component: () => import("@/layouts/Default.vue"),
    children: childRoutes("social"),
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("@/layouts/Empty.vue"),
    children: blankchildRoutes("auth1"),
  },
  {
    path: "/pages",
    name: "pages",
    component: () => import("@/layouts/Empty.vue"),
    children: pagesChildRoutes("default"),
  },
  {
    path: "/store",
    name: "store",
    component: () => import("@/layouts/Default.vue"),
    children: storechildRoutes("store"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/Pages/Error404.vue"),
  },
];

/**
 * Returns a guard for checking the auth-state before entering a route.
 */
export const createAuthGuard =
  (): NavigationGuard => async (to, from, next) => {
    const isLoggedIn =
      store.getters[`${AUTH_MODULE_NAME}/${Getters.IS_AUTHENTICATED}`];

    const currentUser =
      store.getters[`${AUTH_MODULE_NAME}/${Getters.CURRENT_USER}`];
    // if no user is logged in and the requested has th auth flag then redirect the user to the sign in page
    if (!isLoggedIn && to.meta.auth) {
      // it's a private page and the user is not logged -> redirect to sign in page
      next({
        name: "auth1.sign-in1",
        // store the full path to redirect the user after sign in, but only if it is not ROOT (it's just a cosmetic twist)
        query: {
          redirect: to.name === "social" ? undefined : to.fullPath,
        },
      });
      return;
    }
    // do not allow users to visit the sign in page if they are logged in
    if (isLoggedIn && to.meta.isPublic) {
      next(from);
    }

    if (
      currentUser !== null &&
      isLoggedIn &&
      !stringBooleanMapper(currentUser?.mustChangePassword) &&
      to.name !== "auth1.sign-in1"
    ) {
      await userService.updateCurrentUser();
    }

    next();
    return;
  };

const router = createRouter({
  history: createWebHashHistory(),
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => {
    return { top: 0 };
  },
});

// add auth guard to our router
router.beforeEach(createAuthGuard());

export default router;
