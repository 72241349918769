import { Mood } from "./../interfaces/mood";
import { toCamelCase } from "@/helper/utils";
import axios from "axios";

export default {
  /*********
   * Moods
   ********/
  getAllMoods: async (): Promise<Mood[]> => {
    const response = await axios.get("moods");
    return toCamelCase(response.data.data);
  },

  addMood: async (id: number): Promise<any> => {
    const res = await axios.post("users/moods/store ", {
      mood_id: id,
    });
    return res;
  },
  getUserMood: async (): Promise<Mood> => {
    const mood = await axios.get("users/moods/get");
    return mood.data.data;
  },
};
