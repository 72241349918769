import { createSettingsModule, SETTINGS_MODULE_NAME } from "./app-settings";
import { PROFILE_MODULE_NAME, createProfileModule } from "./Profile";
import { createApp } from "vue";
import Vuex, { StoreOptions } from "vuex";
import Setting from "./Setting/index";
import { createAuthModule, AUTH_MODULE_NAME } from "./Auth";
import VuexPersistence from "vuex-persist";
import { createStore } from "vuex";
import { createMoodModule, MOOD_MODULE_NAME } from "./mood";

createApp(Vuex);

const debug = process.env.NODE_ENV !== "production";

/**
 * Type of the main app store, which has basically just module-entries. Those module entries are not known upfront,
 * so that we choose to use `Record<string, unknown>` as type.
 */
export type AppStoreType = Record<string, unknown>;

const modules = {
  Setting,
  [AUTH_MODULE_NAME]: createAuthModule(),
  [PROFILE_MODULE_NAME]: createProfileModule(),
  [SETTINGS_MODULE_NAME]: createSettingsModule(),
  [MOOD_MODULE_NAME]: createMoodModule(),
};

/**
 * Create plugin for local persistence of our Vuex-Store.
 */
const vuexLocal = new VuexPersistence<AppStoreType>({
  storage: window.localStorage,
  // restrict local persistence to auth-module only
  modules: [AUTH_MODULE_NAME, SETTINGS_MODULE_NAME],
});

/**
 * the options for the vuex store.
 */
const options: StoreOptions<AppStoreType> = {
  strict: process.env.NODE_ENV !== "production",
  modules: modules,
  plugins: [vuexLocal.plugin],
};

const appStore = createStore<AppStoreType>({
  modules: modules,
  ...options,
});

export default appStore;
