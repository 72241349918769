
import { defineComponent } from "@vue/runtime-core";
import { useIsRtl } from "@/composable/isRtl";

export default defineComponent({
  name: "CustomModal",
  inheritAttrs: false,
  emits: ["confirm", "cancel"],
  props: {
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    clickToClose: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const isRtl = useIsRtl();
    return {
      isRtl,
    };
  },
});
