import { Mood } from "@/interfaces/mood";
import { Mutations, MoodState } from "./mood.types";

export default {
  [Mutations.SET_MOOD](state: MoodState, mood: Mood): void {
    state.userMood = mood;
  },

  [Mutations.REMOVE_MOOD](state: MoodState): void {
    state.userMood = null;
  },
};
