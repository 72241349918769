import axios from "axios";
import { toCamelCase, toSnakeCase } from "@/helper/utils";
import { ChangeUserPassword, User } from "@/interfaces/user";
import { Profile, UpdateProfileDto } from "@/interfaces/profile";
import store from "@/store";
import { AUTH_MODULE_NAME } from "@/store/Auth";
import { Mutations } from "@/store/Auth/auth.types";

export default {
  /**
   * Changes the user password
   */
  changeUserPassword: async (changeUserPassword: ChangeUserPassword) => {
    const response = await axios.post(
      "user/changePassword",
      toSnakeCase(changeUserPassword)
    );
    return response.data.data;
  },
  /**
   * @returns the profile of the current user, or of the other users (when the id is provided)
   */
  getProfile: async (id?: string): Promise<Profile> => {
    let response;
    if (!id) {
      response = await axios.get("get/profile");
      return response.data.data;
    }
    response = await axios.get("users/" + parseInt(id));
    return response.data.data;
  },
  searchUsers: async (
    term: string,
    page: number
  ): Promise<[User[], number]> => {
    if (!term.trim()) return [[], 0];
    const response = await axios.post(
      "search/users" + (page > 0 ? "?page=" + page : ""),
      { term }
    );
    return [
      toCamelCase(response.data.data),
      toCamelCase(response.data.meta).lastPage as number,
    ];
  },
  updateCurrentUser: async (): Promise<void> => {
    const response = await axios.get("get/profile");
    store.commit(
      `${AUTH_MODULE_NAME}/${Mutations.LOGIN}`,
      toCamelCase(response.data.data)
    );
  },
  updateProfile: async (profile: UpdateProfileDto): Promise<Profile> => {
    const formData = new FormData();
    if (profile) {
      "fullname" in profile &&
        formData.append("fullname", profile.fullname || "");
      "bio" in profile && formData.append("bio", profile.bio || "");
      "address" in profile && formData.append("address", profile.address || "");
      "phone" in profile && formData.append("phone", profile.phone || "");
      "cover" in profile && formData.append("cover", profile.cover || "");
      "avatar" in profile && formData.append("avatar", profile.avatar || "");
      "cover_deleted" in profile &&
        formData.append("cover_deleted", profile.cover_deleted || "no");
      "image_deleted" in profile &&
        formData.append("image_deleted", profile.image_deleted || "no");
    }

    const response = await axios.post("update/profile", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return toCamelCase(response.data.data);
  },
};
