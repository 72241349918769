import { User } from "@/interfaces/user";
import axios from "axios";

export default {
  login: async (username: string, password: string): Promise<User> => {
    const response = await axios.post("auth/login", {
      username,
      password,
    });
    return response.data.data;
  },
  resetPassword: async (email: string): Promise<{ status: string }> => {
    const response = await axios.post("auth/password/forget", {
      email,
    });
    return response.data;
  },
  checkResetPassword: async (
    email: string,
    code: string
  ): Promise<{ status: string }> => {
    const response = await axios.post("auth/password/check ", {
      email,
      reset_code: code,
    });
    return response.data;
  },
  forgotResetPassword: async (
    email: string,
    password: string,
    confirmPassword: string
  ): Promise<{ status: string }> => {
    const response = await axios.post("auth/password/reset", {
      email,
      password,
      password_confirmation: confirmPassword,
    });
    return response.data;
  },
};
