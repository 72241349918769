import {
  MoodActions,
  MoodGetters,
  MoodMutation,
  MoodState,
} from "./mood.types";
import getters from "./mood.getters";
import actions from "./mood.actions";
import mutations from "./mood.mutations";
import { initialMoodState } from "./mood.state";
import { Module } from "vuex";
import { AppStoreType } from "@/store";
import { createNamespacedHelpers } from "vuex-composition-helpers";

export const MOOD_MODULE_NAME = "mood-module";

export const createMoodModule = (): Module<MoodState, AppStoreType> => ({
  namespaced: true,
  state: initialMoodState(),
  mutations,
  actions,
  getters,
});

export const {
  useGetters: useMoodGetters,
  useActions: useMoodActions,
  useMutations: useMoodMutations,
} = createNamespacedHelpers<MoodState, MoodGetters, MoodActions, MoodMutation>(
  MOOD_MODULE_NAME
);
