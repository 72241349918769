enum StringBooleanMapper {
  YES = "yes",
  NO = "no",
}

/**
 * This function makes it possible to change yes, no values to a real boolean values
 * @param value the string value, we want to change to boolean
 */
export const stringBooleanMapper = (value: any) => {
  return value && value.toLowerCase() === StringBooleanMapper.YES;
};

export const booleanStringMapper = (value: any): string => {
  return value ? StringBooleanMapper.YES : StringBooleanMapper.NO;
};
