import { Profile } from "@/interfaces/profile";
import { ProfileState, Getters, ProfileGetters } from "./profile.types";

const getters: ProfileGetters = {
  /**
   * The current state of profile.
   *
   * @param state the current state
   * @returns the profile of the current user
   */
  [Getters.CURRENT_USER_PROFILE]: (state: ProfileState): Profile | null =>
    state.currentUserProfile,
};

export default getters;
