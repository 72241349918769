<template>
  <Spinner v-if="loading" />
  <!-- eslint-disable-next-line vue/component-name-in-template-casing -->
  <notifications position="bottom left" class="notification-wrapper">
    <template #body="props">
      <div class="notification p-2 rounded-1 mb-2">
        <div class="d-flex align-items-center gap-3 text-container">
          <i
            v-if="props.item.type === 'error'"
            class="error ri-error-warning-fill ri-2x"
          ></i>
          <i
            v-if="props.item.type === 'success'"
            class="success ri-check-double-fill ri-2x"
          ></i>
          <div v-html="props.item.text" class="fw-bold" />
        </div>
      </div>
    </template>
  </notifications>
  <ModalsContainer />
  <RouterView />
</template>

<script>
import { onMounted, watchEffect } from "vue";
import { useClientDimensions } from "@/composable/use-client-dimensions";
import Spinner from "@/shared/components/Spinner.vue";
import { SETTINGS_MODULE_NAME } from "@/store/app-settings";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { Getters } from "@/store/app-settings/settings.types";
import { ModalsContainer } from "vue-final-modal";
import { useAuthActions } from "@/store/Auth";
import { Actions } from "@/store/Auth/auth.types";
import { Mutations as SettingsMutations } from "@/store/app-settings/settings.types";
import { useSettingsMutations, useSettingsGetters } from "@/store/app-settings";
import { Getters as SettingsGetters } from "@/store/app-settings/settings.types";
import { useI18n } from "@/composable/use-i18n";

const { useGetters } = createNamespacedHelpers(SETTINGS_MODULE_NAME);

export default {
  name: "App",
  components: {
    Spinner,
    ModalsContainer,
  },
  setup() {
    const { [Actions.LOGIN]: login } = useAuthActions([Actions.LOGIN]);

    const { [SettingsMutations.SET_REL]: setRtl } = useSettingsMutations([
      SettingsMutations.SET_REL,
    ]);

    const { [SettingsGetters.USER_SETTINGS]: userSettings } =
      useSettingsGetters([SettingsGetters.USER_SETTINGS]);

    const i18n = useI18n();

    onMounted(async () => {
      await login();
    });

    watchEffect(() => {
      i18n.locale = userSettings.value?.language || "en";
      setRtl(userSettings.value?.language === "ar");
    });

    /**
     * Get the loading state from store
     */
    const { [Getters.LOADING]: loading } = useGetters([Getters.LOADING]);

    const dim = useClientDimensions();
    return { dim, loading };
  },
};
</script>

<style lang="scss">
@import url("./plugins/icons/remixicon/fonts/remixicon.css");
@import "./styles/base.scss";

.notification-wrapper {
  left: 2rem !important;
  bottom: 2rem !important;
}

// style of the notification itself
.notification {
  background-color: white;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: 0 0 8px;

  .text-container {
    display: flex;
    align-items: center;
    min-height: 32px;
    i.error {
      color: #eb5a46;
    }
    i.success {
      color: green;
    }
  }
}
</style>
