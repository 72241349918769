import { SettingsState } from "./settings.types";
import store from "@/store";

/**
 * The initial state values.
 */
const state: SettingsState = {
  dark: false,
  rtl: false,
  loading: false,
  settings: {
    mood: "",
    language: "",
  },
};

/**
 * Method providing the initial settings state.
 */
export const initialSettingsState = () => ({ ...state });
