import getters from "./profile.getters";
import actions from "./profile.actions";
import mutations from "./profile.mutations";
import { initialProfileState } from "./profile.state";
import { Module } from "vuex";
import { AppStoreType } from "@/store";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import {
  ProfileActions,
  ProfileGetters,
  ProfileState,
} from "@/store/Profile/profile.types";

export const PROFILE_MODULE_NAME = "profile-module";

/**
 * The Profile store.
 */
export const createProfileModule = (): Module<ProfileState, AppStoreType> => ({
  namespaced: true,
  state: initialProfileState(),
  mutations,
  actions,
  getters,
});

export const {
  useGetters: useProfileGetters,
  useActions: useProfileActions,
  useMutations: useProfileMutations,
} = createNamespacedHelpers<ProfileState, ProfileGetters, ProfileActions>(
  PROFILE_MODULE_NAME
);
