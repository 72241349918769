import getters from "./settings.getters";
// import actions from "./settings.actions";
import mutations from "./settings.mutations";
import { initialSettingsState } from "./settings.state";
import { Module } from "vuex";
import { AppStoreType } from "@/store";
import { SettingsGetters, SettingsState } from "./settings.types";
import { createNamespacedHelpers } from "vuex-composition-helpers";

export const SETTINGS_MODULE_NAME = "settings-module";

/**
 * The settings store.
 */
export const createSettingsModule = (): Module<
  SettingsState,
  AppStoreType
> => ({
  namespaced: true,
  state: initialSettingsState(),
  mutations,
  getters,
});

export const {
  useGetters: useSettingsGetters,
  useActions: useSettingsActions,
  useMutations: useSettingsMutations,
} = createNamespacedHelpers<SettingsState, SettingsGetters>(
  SETTINGS_MODULE_NAME
);
