import { useHttpErrorNotify } from "./../../composable/useNotify";
import { toCamelCase } from "@/helper/utils";
import moodService from "@/services/mood.service";
import { Actions, Mutations } from "./mood.types";

const actions = {
  [Actions.GET_MOOD]: async ({ commit }): Promise<void> => {
    const mood = await moodService.getUserMood();
    if (mood) {
      commit(Mutations.SET_MOOD, toCamelCase(mood));
    }
  },
  [Actions.ADD_MOOD]: async ({ commit }, moodId: number): Promise<void> => {
    try {
      const res = await moodService.addMood(moodId);
      if (res) {
        const mood = await moodService.getUserMood();
        commit(Mutations.SET_MOOD, toCamelCase(mood));
      }
    } catch (e) {
      useHttpErrorNotify(e);
    }
  },
};

export default actions;
