import { Profile } from "@/interfaces/profile";
import { ProfileState, Mutations } from "./profile.types";

export default {
  /**
   * Sets a the user profile.
   * @param state the current state
   */
  [Mutations.SET_PROFILE](state: ProfileState, profile: Profile): void {
    state.currentUserProfile = profile;
  },
};
