import { SETTINGS_MODULE_NAME } from "./../app-settings/index";
import { User } from "@/interfaces/user";
import { Actions, AuthActions, Mutations } from "./auth.types";
import { toCamelCase } from "@/helper/utils";
import authService from "@/services/auth.service";
import userService from "@/services/user.service";
import { ChangeUserPassword } from "@/interfaces/user";
import { useHttpErrorNotify } from "@/composable/useNotify";
import { Mutations as SettingsMutation } from "../app-settings/settings.types";

const actions: AuthActions = {
  /**
   * Performs the login of the user.
   * @param commit callback for committing a mutation
   * @param payload payload with credentials
   */
  [Actions.LOGIN]: async (
    { commit },
    payload: { username: string; password: string }
  ): Promise<User | undefined> => {
    if (!payload) {
      return;
    }
    const user = await authService.login(payload.username, payload.password);
    if (user) {
      commit(Mutations.LOGIN, toCamelCase(user));

      commit(
        `${SETTINGS_MODULE_NAME}/${SettingsMutation.SET_SETTINGS}`,
        user.settings,
        { root: true }
      );

      commit(
        `${SETTINGS_MODULE_NAME}/${SettingsMutation.SET_REL}`,
        user.settings.language === "ar",
        { root: true }
      );
    } else {
      commit(Mutations.LOGOUT);
    }

    return user;
  },

  /**
   * Performs the login of the user.
   * @param commit callback for committing a mutation
   * @param payload payload with old password, new password and confirmation
   */
  [Actions.CHANGE_PASSWORD]: async (
    _,
    payload: ChangeUserPassword
  ): Promise<void> => {
    const user = await userService.changeUserPassword(payload);
  },

  /**
   * logs the user out.
   * @param commit callback for committing a mutation
   */
  [Actions.LOGOUT]: async ({ commit }): Promise<void> => {
    try {
      commit(Mutations.LOGOUT);
      commit(`${SETTINGS_MODULE_NAME}/${SettingsMutation.SET_SETTINGS}`, null, {
        root: true,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error while logout", error);
      useHttpErrorNotify(error);
    } finally {
      commit(Mutations.LOGOUT);
      commit(`${SETTINGS_MODULE_NAME}/${SettingsMutation.SET_SETTINGS}`, null, {
        root: true,
      });
    }
  },
};

export default actions;
