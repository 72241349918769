import { createI18n, LocaleMessages, VueMessageType } from "vue-i18n";

/**
 * converts the JSON locales to js object
 * @returns
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {} as LocaleMessages<VueMessageType>;
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

// Create VueI18n instance with options
const i18n = createI18n({
  locale: "en", // set locale
  messages: loadLocaleMessages(),
  enableInSFC: false,
});

export default i18n;
