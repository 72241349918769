import { ProfileState } from "./profile.types";

/**
 * The initial state values.
 */
const state: ProfileState = {
  currentUserProfile: null,
};

/**
 * Method providing the initial profile state.
 */
export const initialProfileState = () => ({ ...state });
