import { AuthState } from "./auth.types";

/**
 * The initial state values.
 */
const state: AuthState = {
  currentUser: null,
  isAuthenticated: false,
};

/**
 * Method providing the initial auth state.
 */
export const initialAuthState = () => ({ ...state });
