import { Profile } from "@/interfaces/profile";
import { UserSettings } from "@/interfaces/user";
import { AppStoreType } from "@/store";
import { ActionContext, ActionTree, GetterTree } from "vuex";

/****************************
 * Settings-Store interfaces
 ****************************/
/**
 * Interface for the Settings state.
 */
export interface SettingsState {
  dark: boolean;
  rtl: boolean;
  loading: boolean;
  settings: UserSettings;
}

/**
 * Enumeration for the mutations of the settings state.
 */
export enum Mutations {
  SET_LOADING = "SET_LOADING",
  SET_SETTINGS = "SET_SETTINGS",
  SET_REL = "SET_REL",
}

/**
 * Enumeration for the actions of the settings state.
 */

export enum Getters {
  DARK = "DARK",
  IS_RTL = "IS_RTL",
  LOADING = "LOADING",
  CURRENT_USER_CONFIGS = "CURRENT_USER_CONFIGS",
  USER_SETTINGS = "USER_SETTINGS",
}

/**
 * An Interface describes the shape of settings getters
 */
export interface SettingsGetters
  extends GetterTree<SettingsState, AppStoreType> {
  [Getters.LOADING]: (state: SettingsState) => boolean;
  [Getters.USER_SETTINGS]: (state: SettingsState) => UserSettings;
  [Getters.IS_RTL]: (state: SettingsState) => boolean;
}

// /**
//  * Enumeration for the actions of the profile state.
//  */
// export enum Actions {
//   GET_PROFILE = "GET_PROFILE",
// }
// /**
//  * An Interface describes the shape of auth actions
//  */
// export interface ProfileActions extends ActionTree<ProfileState, AppStoreType> {
//   [Actions.GET_PROFILE]: ({
//     commit,
//   }: ActionContext<ProfileState, AppStoreType>) => Promise<void>;
// }
