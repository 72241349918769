import getters from "./auth.getters";
import actions from "./auth.actions";
import mutations from "./auth.mutations";
import { initialAuthState } from "./auth.state";
import { Module } from "vuex";
import { AppStoreType } from "@/store";
import { AuthActions, AuthGetters, AuthState } from "./auth.types";
import { createNamespacedHelpers } from "vuex-composition-helpers";

export const AUTH_MODULE_NAME = "auth-module";

/**
 * The authenticate store.
 */
export const createAuthModule = (): Module<AuthState, AppStoreType> => ({
  namespaced: true,
  state: initialAuthState(),
  mutations,
  actions,
  getters,
});

export const {
  useGetters: useAuthGetters,
  useActions: useAuthActions,
  useMutations: useAuthMutations,
} = createNamespacedHelpers<AuthState, AuthGetters, AuthActions>(
  AUTH_MODULE_NAME
);
