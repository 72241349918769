import {
  User,
  ChangeUserPassword,
  UserPoints,
  Configs,
} from "@/interfaces/user";
import { AppStoreType } from "@/store";
import { ActionContext, ActionTree, GetterTree } from "vuex";

/************************
 * Auth-Store interfaces
 ************************/
/**
 * Interface for the authentication state.
 */
export interface AuthState {
  currentUser: User | null;
  isAuthenticated: boolean;
}

/**
 * Enumeration for the mutations of the authentication state.
 */
export enum Mutations {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
}

/**
 * Enumeration for the actions of the authentication state.
 */
export enum Getters {
  IS_AUTHENTICATED = "IS_AUTHENTICATED",
  CURRENT_USER = "CURRENT_USER",
  CURRENT_USER_TOKEN = "CURRENT_USER_TOKEN",
  CURRENT_USER_POINTS = "CURRENT_USER_POINTS",
  CURRENT_USER_CONFIGS = "CURRENT_USER_CONFIGS",
}

/**
 * An Interface describes the shape of auth getters
 */
export interface AuthGetters extends GetterTree<AuthState, AppStoreType> {
  [Getters.IS_AUTHENTICATED]: (state: AuthState) => boolean;
  [Getters.CURRENT_USER]: (state: AuthState) => User | null;
  [Getters.CURRENT_USER_TOKEN]: (state: AuthState) => string | null;
  [Getters.CURRENT_USER_POINTS]: (state: AuthState) => UserPoints;
  [Getters.CURRENT_USER_CONFIGS]: (state: AuthState) => Configs | null;
}

/**
 * Enumeration for the actions of the authentication state.
 */
export enum Actions {
  LOGIN = "LOGIN",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  LOGOUT = "LOGOUT",
}
/**
 * An Interface describes the shape of auth actions
 */
export interface AuthActions extends ActionTree<AuthState, AppStoreType> {
  [Actions.LOGIN]: (
    { commit }: ActionContext<AuthState, AppStoreType>,
    payload: { username: string; password: string }
  ) => Promise<User | undefined>;

  [Actions.LOGOUT]: ({
    commit,
  }: ActionContext<AuthState, AppStoreType>) => Promise<void>;

  [Actions.CHANGE_PASSWORD]: (
    { commit }: ActionContext<AuthState, AppStoreType>,
    payload: ChangeUserPassword
  ) => Promise<void>;
}
