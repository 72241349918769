import { UserSettings } from "./../../interfaces/user";
import { SettingsState, Mutations } from "./settings.types";

export default {
  /**
   * Sets a the state of loading.
   * @param state the current settings state
   */
  [Mutations.SET_LOADING](state: SettingsState, loadingState: boolean): void {
    state.loading = loadingState;
  },

  [Mutations.SET_SETTINGS](state: SettingsState, settings: UserSettings): void {
    state.settings = settings;
  },

  [Mutations.SET_REL](state: SettingsState, isRtl: boolean): void {
    state.rtl = isRtl;
    document
      .getElementsByTagName("html")[0]
      .setAttribute("dir", isRtl ? "rtl" : "ltr");
  },
};
