import { AuthState, Mutations } from "./auth.types";
import { User } from "@/interfaces/user";
import router from "@/router";

export default {
  /**
   * Sets a successful authorization.
   * @param state the current state
   */
  [Mutations.LOGIN](state: AuthState, currentUser: User): void {
    state.currentUser = { ...state.currentUser, ...currentUser };
    state.isAuthenticated = currentUser && Object.keys(currentUser).length > 0;
  },

  /**
   * Removes the authorization.
   * @param state the current state
   */
  [Mutations.LOGOUT](state: AuthState): void {
    state.currentUser = null;
    state.isAuthenticated = false;
    router.push({ name: "auth1.sign-in1" });
  },
};
