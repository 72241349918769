import { AuthState, Getters, AuthGetters } from "./auth.types";
import { User, Role, UserPoints, Configs } from "@/interfaces/user";

const getters: AuthGetters = {
  /**
   * The current state of authorization.
   *
   * @param state the current state
   * @returns true if there is a authorized signed user
   */
  [Getters.IS_AUTHENTICATED]: (state: AuthState): boolean =>
    state.isAuthenticated,

  /**
   * The current user
   *
   * @param state the current state
   * @returns The current user
   */
  [Getters.CURRENT_USER]: (state: AuthState): User | null => state.currentUser,

  /**
   * The current user token
   *
   * @param state the current state
   * @returns  The current user token
   */
  [Getters.CURRENT_USER_TOKEN]: (state: AuthState): string | null =>
    state.currentUser?.token || null,

  /**
   * The current user roles
   *
   * @param state the current state
   * @returns  The current user roles
   */
  [Getters.CURRENT_USER_POINTS]: (state: AuthState): UserPoints => ({
    incomingWallet: state.currentUser?.incomingWallet || 0,
    outcomingWallet: state.currentUser?.outcomingWallet || 0,
  }),

  /**
   * The Configs of the current user
   *
   * @param state the current state
   * @returns  The Configs
   */
  [Getters.CURRENT_USER_CONFIGS]: (state: AuthState): Configs | null =>
    state.currentUser?.configs || null,
};

export default getters;
