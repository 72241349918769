import { initializeAxios } from "./http/index";
import { createApp, InjectionKey } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import axios, { AxiosInstance } from "axios";
import VueAxios from "vue-axios";
import i18n from "./i18n";
import { VueI18n } from "vue-i18n";
import vfmPlugin from "vue-final-modal";
import Notifications from "@kyvg/vue3-notification";
import "floating-vue/dist/style.css";

export const axiosSymbol: InjectionKey<AxiosInstance> = Symbol("AXIOS");
export const i18Symbol: InjectionKey<VueI18n> = Symbol("I18N");

const app = createApp(App);

initializeAxios();

app
  .use(store)
  .use(router)
  .use(VueSweetalert2)
  .use(i18n)
  .provide(i18Symbol, i18n.global)
  .use(VueAxios, axios)
  .provide(axiosSymbol, app.config.globalProperties.axios)
  .use(vfmPlugin)
  .use(Notifications)
  .mount("#app");

app.mixin({
  data() {
    return {
      breadcrumb: true,
    };
  },
});

// Global Components
app.component(
  "CustomModal",
  require("@/shared/components/CustomModal.vue").default
);
app.component("Spinner", require("@/shared/components/Spinner.vue").default);
app.component(
  "GlobalSpinner",
  require("./shared/components/GlobalSpinner.vue").default
);
app.component("IqCard", require("./components/custom/cards/iq-card").default);
app.component("loader", require("./components/custom/loader/Loader").default);

app.component("TabNav", require("./components/bootstrap/tab/tab-nav").default);
app.component(
  "TabNavItems",
  require("./components/bootstrap/tab/tab-nav-items").default
);
app.component(
  "TabContent",
  require("./components/bootstrap/tab/tab-content").default
);
app.component(
  "TabContentItem",
  require("./components/bootstrap/tab/tab-content-item").default
);
app.component(
  "dropdown",
  require("./components/bootstrap/dropdown/dropdown").default
);
app.component(
  "dropdown-items",
  require("./components/bootstrap/dropdown/dropdown-items").default
);
app.component(
  "collapse",
  require("./components/bootstrap/collapse/collapse").default
);
app.component(
  "collapse-content",
  require("./components/bootstrap/collapse/collapse-content").default
);
app.component("modal", require("./components/bootstrap/modal/modal").default);
app.component(
  "model-header",
  require("./components/bootstrap/modal/model-header").default
);
app.component(
  "model-body",
  require("./components/bootstrap/modal/model-body").default
);
app.component(
  "model-footer",
  require("./components/bootstrap/modal/model-footer").default
);

export { app };
