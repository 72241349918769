import { NotificationsOptions, notify } from "@kyvg/vue3-notification";
import { AxiosError } from "axios";

const baseNotificationsOptions: NotificationsOptions = {
  speed: 200,
  duration: 3000,
};

const errorNotificationsOptions: NotificationsOptions = {
  type: "error",
};

const successNotificationsOptions: NotificationsOptions = {
  type: "success",
};

export const useSuccessNotify = (
  notificationsOptions: NotificationsOptions
): void => {
  if (notificationsOptions.title && notificationsOptions.text) {
    notify({
      ...baseNotificationsOptions,
      ...successNotificationsOptions,
      title: notificationsOptions.title,
      text: notificationsOptions.text,
    });
  }
};

export const useErrorNotify = (
  notificationsOptions: NotificationsOptions
): void => {
  if (notificationsOptions.title && notificationsOptions.text) {
    notify({
      ...baseNotificationsOptions,
      ...errorNotificationsOptions,
      title: notificationsOptions.title,
      text: notificationsOptions.text,
    });
  }
};

export const useHttpErrorNotify = (error: unknown): void => {
  const text = (error as AxiosError).response?.data?.errors?.join(
    " "
  ) as string;
  if (text)
    if (text) {
      useErrorNotify({
        title: "Error",
        text,
      });
    }
};
